import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { 
  Box, 
  Button,
  Link,
  Container, 
  Typography, 
  Grid
} from '@mui/material';

import ProductImage from "../../assets/images/products.png";

import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import EnergySavingsLeafTwoToneIcon from '@mui/icons-material/EnergySavingsLeafTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import BoltTwoToneIcon from '@mui/icons-material/BoltTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';

import styles from "../../global.scss";
import "./styles.scss";

const ProductsPage = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

  return (
    <Container maxWidth="xl">
      <Box className="products">
        <Box sx={{ 
            margin: "15px 0px 50px 0px",
            marginBottom: { xs: "150px", sm: "150px", md: "50px", lg: "50px"},
            overflow: "hidden" 
          }} 
          className="flexCenterSBRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h4", sm: "h4", md: "h3", lg: "h3" },
            }}>Products</Typography>
          <Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
						  src={ProductImage}
              alt='product'
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
        </Box>

        <Box className="products__section" 
          id={"web-app"}>
          <Typography variant='h4' mb={"60px"} >Lusid Web App</Typography>
          {/* <Typography variant='h5' textAlign={"center"} mb={"20px"}>
            Simplify Your Payments with Ease
          </Typography> */}
          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }} 
            mb={"20px"}>
              Welcome to Lusid Web App, where payment management is revolutionized. With state-of-the-art technology, Lusid offers seamless payments using virtual cards and instant payments.
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "40px" }}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<SecurityTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Efficiency meets security</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Generate single-use virtual cards instantly for added fraud protection. Each payee gets their own card, ensuring security and isolating payments.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<BoltTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Instant payments</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    No more waiting for funds to clear. Make instant payments with a few clicks. Pay freelancers, vendors, or service providers promptly.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LocalAtmTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Streamlined payment management</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Say goodbye to spreadsheets. Track payment history, view pending payments, and schedule recurring payments in one dashboard.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<TuneTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Flexibility and control</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Seamlessly integrate with existing payment systems. Sync with accounting software and choose from various payment methods.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }} 
            mb={"20px"}>
              Experience the future of payment management with Lusid Web App. Your payees will thank you!
          </Typography>

          <Link 
						href="https://business.lusid.ai/auth"
						target="_blank" rel="noopener noreferrer"
						>
            <Button variant='outlined'>Sign In</Button>
          </Link>
        </Box>

        <Box className="products__section"
          id={"mobile-app"}>
          <Typography variant='h4' mb={"60px"}>Lusid Mobile App</Typography>
          {/* <Typography variant='h5' textAlign={"center"} mb={"20px"}>
            Simplify Your Payments with Ease
          </Typography> */}
          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }}
            mb={"20px"}>
            Lusid Mobile App is a convenient mobile application that streamlines payment management by allowing users to make payments to payees using a single-use or multi-use virtual card. 
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "40px" }}>
						<Grid item xs={12} sm={12} md={4} lg={12}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<SpeedTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Frictionless payments</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    With a simple tap or QR code scan at the point-of-sale terminal, payments can be made effortlessly, eliminating the need for physical cards and the associated hassle.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={12}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<BoltTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Reconciliation</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    By storing all payment related information securely within the app, Lusid eliminates the need to save real cards. Instead, users can generate virtual cards with specific transaction details for various purposes such as gym memberships, streaming services, and monthly subscriptions. This ensures easy access and efficient management of payment data for simplified reconciliation.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={12}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<LocalAtmTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
									<Typography variant='h6'>Manage Spend</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    In addition to simplifying payments, Lusid Mobile App offers expense tracking capabilities. Users can access a comprehensive transaction record, enabling them to monitor spending habits and identify any discrepancies. This feature facilitates budgeting and provides users with greater control over their financial lives.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Typography variant='body1' 
            sx={{ color: "grey", marginBottom: "20px" }}
            mb={"20px"}>
            Experience the simplicity, security, and efficiency of Lusid Mobile App by giving it a try. Start your journey towards streamlined payment management and take back control of your finances with Lusid Mobile App.
          </Typography>

          {/* <Box className="flexCenter_Row" gap={"20px"}>
            <Button variant='outlined'>
              <Box className="flexCenterCenterRow" gap={"10px"}>
                <AppleIcon />
                App Store
              </Box>
            </Button>
            <Button variant='outlined'>
              <Box className="flexCenterCenterRow" gap={"10px"}>
                <AndroidIcon />
                Play Store
              </Box>
            </Button>
          </Box> */}
        </Box>

        <Box className="products__section">
          <Typography variant='h4' mb={"60px"} id={"extension"}>Lusid Extension</Typography>
          <Typography variant='h5' textAlign={"center"} mb={"20px"}>
            Streamline Your Business Transactions with Lusid Card Extension
          </Typography>

          <Typography variant='body1' sx={{ color: "grey" }}>
            In today&#39;s fast-paced digital world, businesses are constantly seeking ways to
            optimize their operations and improve efficiency. One area that often poses
            challenges is managing corporate expenses and credit card transactions.
            However, with the advent of innovative technologies like Robotic Process
            Automation (RPA), there&#39;s now a solution that can revolutionize the way
            businesses handle their financial transactions. In this blog post, we&#39;ll take a
            closer look at Lusid Card Extension product and how it can simplify and
            streamline your payment processes.
          </Typography>


          <Box className="products__ques">
            <Typography variant='h5' mb={"20px"}>
              What is Lusid Card Extension?
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Lusid Card Extension is a cutting-edge solution developed by Lusid System, a
              leading provider of automation software. This innovative product combines the
              power of RPA with the convenience of virtual credit cards, creating a seamless
              and secure experience for businesses.
            </Typography>
          </Box>

          <Box className="products__ques">
            <Typography variant='h5' mb={"20px"}>
              How does it work?
            </Typography>
            <Typography variant='body1' sx={{ color: "grey" }}>
              Lusid Card Extension works by automating the entire credit card payment
              process, from the generation of virtual card numbers to the reconciliation of
              transactions. This automation eliminates the need for manual data entry, reduces
              human errors, and saves valuable time for your finance team.
            </Typography>
          </Box>

          <Typography variant='h4' textAlign={"center"} mt={"150px"} mb={"40px"}>
            Benefits of integrated Lusid products
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "20px", marginBottom: "16px" }}>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<SecurityTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
									<Typography variant='h6'>Enhanced Security</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    With Lusid, sensitive credit card
                    information is no longer exposed during transactions. By generating unique
                    virtual card numbers for each transaction, the risk of fraud and data breaches is
                    significantly minimized.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<EnergySavingsLeafTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
									<Typography variant='h6'>Improved Efficiency</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Automating the payment process eliminates the need for
                    manual data entry, freeing up your finance team to focus on more value-added
                    tasks. This increased efficiency translates into cost savings and improved
                    productivity.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<ReceiptTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
									<Typography variant='h6'>Streamlined Reconciliation</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Reconciling credit card transactions can be a
                    tedious and time-consuming task. However, with Lusid, the
                    reconciliation process becomes seamless. The system automatically matches
                    transactions, simplifying the overall process and reducing errors.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Box className="home__products-features flexFSSBRow" gap="15px">
								<SupervisedUserCircleTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
								<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
									<Typography variant='h6'>Greater Control and Visibility</Typography>
									<Typography variant='body1' sx={{ color: "grey" }}>
                    Lusid provides businesses with
                    real-time visibility into their expenses. Through detailed reporting and analytics,
                    you can easily track and manage your company&#39;s spending, enhancing control
                    and decision-making capabilities.
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>

          <Box className="products__benefits">
            {/* <Typography variant='h5' mb={"20px"}>
              Conclusion
            </Typography> */}
            <Typography variant='body1' sx={{ color: "grey" }}>
              In a world where time is money, investing in automation solutions that optimize
              business processes is a smart move for any organization. Lusid
              offers a powerful solution to simplify and streamline your payment processes,
              resulting in enhanced security, improved efficiency, and greater control over
              expenses.
            </Typography>
            <br/><br/>

            <Typography variant='body1' sx={{ color: "grey" }}>
              Lusid is essential for every business as it presents an intriguing opportunity to optimize financial operations and
              drive growth. By leveraging this technology, you can empower your finance team
              to focus on strategic initiatives and take your business to new heights.
              So why not explore the possibilities that Lusid has to offer?
            </Typography>
          </Box>

          {/* <Box className="flexCenterCenterRow" mt={"40px"}>
            <Link 
							href="https://business.lusid.ai/register"
							target="_blank" rel="noopener noreferrer"
							>
              <Button variant='outlined'>
                Get started
              </Button>
            </Link>
          </Box> */}

        </Box>

        {/* <Box className="products__section"
          id={"plugin"}>
          <Typography variant='h4'>Lusid Plugin</Typography>
        </Box> */}


      </Box>
		</Container>
  )
}

export default ProductsPage