import React from 'react';

import { 
	Box,
	Container, 
	Typography 
} from '@mui/material';

import styles from "../../global.scss";
import "./styles.scss";

const TermsAndConditionsPage = () => {
  return (
    <Container maxWidth="xl">
      <Box className="terms">
        <Box sx={{ margin: "15px 0px 100px 0px", borderRadius: "30px", backgroundColor: styles["primary_light"], height: "250px" }} 
          className="flexCenterCenterRow">
          <Typography
            sx={{ 
              textAlign: "center",
              typography: { xs: "h5", sm: "h4", md: "h3", lg: "h3" },
            }}>Terms of service</Typography>
        </Box>
        <Typography variant='h5'>1. Introduction</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
        Where the present Terms of Service (“TOS”) refer to “Lusid”, they may refer to Lusid Inc., or lusid.ai, or LusidPay.com and affiliates, and / or their respective shareholders, officers, directors, employees, agents, partners, principals, representatives, successors, and assigns (collectively “Representatives”), depending on the context being used. Any reference to “we”, “our”, or “us” in these TOS refers to Lusid Inc. 
        <br/> <br/>
        “Platform” means that part of our website or any software application that sets out terms specific to the Platform that we provide you, including details of any Fees. “Platform” refers to the software services accessed through the Website that Lusid has developed and / or licensed to allow Payee to send payment requests & receive payments from Payers & other related services. 
        <br/> <br/>
        “Website” is the website located at https://www.lusid.ai or https://www.lusidpay.com which are owned and operated by Lusid, without limiting the generality of the foregoing and includes all subdomains and subpages, present and future. Without limiting the generality of the foregoing, or an app where the Platform services are accessed by a Logged-in User. 
        <br/> <br/>
        “User” refers to the individual who has created an account to use the Platform for their own benefit and/ or on behalf of a company, partnership, association, or other legal entity (a “Legal Entity”). When a User has created an account to use the Platform and is logged in, they may be referred to, in these TOS, as a “Logged-in User”. The Legal Entity that the User is representing may be referred to in these TOS as a Payee or Payer. 
        <br/> <br/>
        “Payee” refers to a User of the Platform. An “Active Payee” is a Payee who has received, and/or may be receiving a payment of an invoice or an online purchase from a User via the Platform. 
        <br/> <br/>
        “Payer” refers to a User of the Platform. An “Active Payer” is the one who has made a payment, and/ or may be making a payment of an invoice or an online purchase to a Payee via the Platform. 
        <br/> <br/>
        "Fees” means the fees for provision of the Platform as described on the Lusid Website or otherwise set out in our Platform, including but not limited to Payment Service Fees (as applicable). 
        <br/> <br/>
        “Payment Facilitators” is a collective term referred to Plaid or Payment Processor Orum, or any other service provider collectively used for payment processing. 
        <br/> <br/>
        "Payment Service Fees” means the service fee for processing the payments as described on the Lusid Website or otherwise set out in your Platform.
        </Typography>

        <Typography variant='h5'>2. TOS Acceptance</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          By interacting with the Platform in any way, including but not limited to: (i) clicking or tapping the acceptance button when signing up for an account; or (ii) accepting or making a payment through the Platform, you hereby accept the TOS without any reservations, modifications, additions, or deletions. If you do not agree to all the provisions contained in the TOS, you are not authorized to use the Platform.
          <br/> <br/>
          The TOS is a legal and binding agreement between you and us. By accepting this Agreement, you certify that you have all the necessary rights, permissions, and authority to do so. If you are accepting on behalf of your employer or another legal entity, you represent and warrant that: (i) you have the full legal authority to bind your employer or such entity to this Agreement; (ii) you agree to this Agreement on behalf of your employer or such entity, and (iii) you have read and understand this Agreement. If you do not have the legal authority to bind your employer or the applicable entity, you must not accept this Agreement or access the Platform.
          <br/> <br/>
          Lusid reserves the right to modify or replace any of these TOS, at any time and without prior notice. Any changes to the TOS can be found on our Website. It is your responsibility to check the TOS periodically for changes. Unless Lusid is legally required to provide you with notice, your continued use of the Platform demonstrates your agreement to any changes. If you do not agree to the modifications, you should discontinue using the Platform.
          {/* Permission is granted to temporarily download one copy of the materials on Lusid's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
          <ul style={{ marginLeft: "40px" }}>
            <li>modify or copy the materials;</li>
            <li>use the materials for any commercial purpose or for any public display;</li>
            <li>attempt to reverse engineer any software contained on Lusid's Website;</li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
          </ul>
          This will let Lusid to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. */}
        </Typography>

        <Typography variant='h5'>3. Contact Us</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          If you have any questions regarding the Platform, please contact us at help@lusid.ai or visit https://support.lusidpay.com for more information.
        </Typography>

        <Typography variant='h5'>4. The Platform</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Details of the Platform, including all the features and integrations in any offering that forms part of the Platform, are set out on the Lusid website or via the Platform. Lusid reserves the right to upgrade, modify or remove any of its Platform from time to time by creating a new release or version of any Platform or replacing any offering that forms part of the Platform with an alternative offering, including updates, enhancements, extensions, or replacements of the functionality of such Platform.   
        </Typography>

        <Typography variant='h5'>5. General Code of Conduct for Use of the Website and the Platform</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
         
          In addition to the more specific rules found elsewhere in these TOS, you agree that by interacting with the Website or Platform in any way, you will: 
          <ul style={{ marginLeft: "40px" }}>
            <li>NOT use the Website or Platform in any manner that in any way violates these TOS or any other applicable policy posted on the Website or in the Platform by Lusid</li>
            <li>NOT use the Website or Platform in any manner that violates any intellectual property rights of Lusid or any third party</li>
            <li>NOT use the Website or Platform in any manner to propagate spam, including but not limited to, unsolicited advertising or bulk electronic mail or messages, including to link to a spam or phishing website</li>
            <li>NOT use the Website or Platform in any manner to propagate software viruses, Trojan horses, worms, or any other malicious or non-malicious computer code, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper functioning of any software, hardware, or telecommunications equipment in any form whether belonging to Lusid or a third party, or to damage or obtain unauthorized access to any system, data, password or other information (whether personal data or not) of Lusid, other Platform Users, or any other third party</li>
            <li>NOT: (1) take any action that imposes or may impose (as determined by Lusid in its sole discretion) an unreasonable or disproportionately large load on Lusid’s (or its third party providers’) infrastructures; (2) interfere or attempt to interfere with the proper functioning of the Website or Platform or any activities conducted on or via the Website or Platform; (3) bypass any measures Lusid may use to prevent or restrict access to the Platform or any element thereof; (4) use manual or automated software, devices, or other processes to “crawl” or “spider” any page or portion of the Website or Platform; or (5) harvest or scrape any content from the Website or Platform in an unreasonable manner</li>
            <li>NOT take any action or use any process that removes, modifies, disables, blocks, obscures or otherwise impairs any advertising or other promotions in connection with the Website or Platform</li>
            <li>NOT use the Website or Platform in any way to collect information (whether personal information or not) of any third party or in violation of our Privacy Policy, except as permitted by the nature and function of the Website or Platform</li>
            <li>NOT use the Website or Platform to advertise or promote products or services that are not expressly approved in advance in writing by Lusid, or as otherwise permitted by the nature of the Platform</li>
            <li>NOT interfere with any third party’s use or enjoyment of the Website or Platform</li>
            <li>NOT do anything or encourage conduct that would constitute a criminal offense or give rise to civil liability, or is any way unlawful, illegal, fraudulent or harmful to any third party</li>
            <li>NOT attempt to do any of the foregoing prohibitions</li>
            <li>Use the Website and Platform in good faith, and in compliance with all the applicable local, state or provincial, national, and international laws</li>
          </ul>
        </Typography>

        <Typography variant='h5'>6. Platform Requirements</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          a. Account Limitations - 
          You may begin using the Platform with a free account. However, Lusid reserves the right, in its absolute discretion, to determine your free account eligibility. Platform is free to join however there might be fee for additional services provided. Certain products and features are available only in specific jurisdictions.<br/><br/>
          b. Age Requirement - 
          You must be at least eighteen (18) years of age, or the age of majority in the jurisdiction you reside and from which you access the Platform to create an account or be able to use the Platform.<br/><br/>
          c. Account Accessibility - 
          Accounts are available to only who provide the required information, subject to the restrictions and conditions as outlined elsewhere in TOS. 
          You agree to immediately notify us of any unauthorized use of your account or any other breach of security regarding your account. If you are a Logged-in User, it is strongly recommended to log out of the account at the end of every session, or not leave a logged-in account unattended for any period of time. 
          Lusid and/ or its Representatives will not be held liable for any losses or damages, direct or indirect, pecuniary or non-pecuniary, resulting from your failure to log out at the end of the session, an unattended logged-in session, or a third party using the Platform with your Account Information and accessing your account through any means, and disclaims any responsibility in this regard. 
          Lusid reserves the right to suspend or terminate your account, at its sole discretion, at any time and for any reason, including but not limited to whether you have violated the letter or spirit of the TOS, as more fully described here in below.<br/><br/>
          d. Account Information - 
          By submitting Account Information, you represent and warrant that the Account Information is true and accurate to the best of your knowledge. Submitting false or misleading Account Information may result in you being banned from using the Platform, at our sole discretion. 
          Lusid reserves the right to verify your submitted Account Information and retains absolute discretion to refuse to approve your account for any reason, including but not limited to, believing that you have submitted false or misleading Account Information. Without limiting the generality of the Disclaimer of Warranties further in these TOS, Lusid and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you resulting from Lusid’s refusal to approve your account, and Lusid disclaims any responsibility thereto.<br/><br/>
          e. Additional Users - 
          As a Logged-in User using the Platform on behalf of a Legal Entity, you are able to add additional Users to your account for no additional cost. You acknowledge and agree that you are solely responsible for the use of the Platform by these additional Users through your account and are responsible for any violations of these TOS by those additional Users. 
        </Typography>

        <Typography variant='h5'>7. Subscription and Payment Processing Fees</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          a. Fees - Subscription Fees - 
          Our product needs a subscription. The amount of the monthly Subscription Fees is available on the Lusid Website or via the Platform and will be displayed and charged in US Dollars. By signing up for a Subscription you agree to pay the Subscription Fees presented to you upon signing up, plus any applicable taxes. 
          <br/>
          You will be charged the Subscription Fees on the date you sign up for a Subscription to the Platform (the “Initial Billing Date”). The Subscription Fees shall subsequently be charged on the monthly or yearly anniversary of the Initial Billing Date, as applicable depending on the option you have chosen. The payment period of your subscription, whether a month or a year, shall be referred to as a “Billing Period”. 
          Your Subscription automatically renews at the end of every Billing Period. If you wish to cancel your Subscription and avoid paying the Subscription Fees for the next Billing Period, you must do so in writing 30 days prior to the end of the current Billing Period. You can cancel your Subscription and avoid paying the Subscription Fees for the next Billing Period by accessing the Subscription information section within your dashboard when logged-in, or by emailing help@lusid.ai
          If you cancel your Subscription prior to the end of the Billing Period, you shall not be entitled to any refund of any Subscription Fees already paid for that Billing Period, prorated or otherwise.   <br/>  <br/>
          b. Fees - Payment Processing Fees - 
          Lusid charges Payment Processing Fee for each payment processed using Lusid Platform which cannot be negotiated/ cancelled/ refunded for any reason.  <br/>  <br/>
          c. Payment Processing by Third-Party Payment Processors - 
          All payments are made using a secure https:// connection, and payment processing is handled through a Third-Party Payment Processor, though this is subject to change without notice. Lusid and the Third-Party Payment Processors & Facilitators are unaffiliated companies and Lusid has no influence on the operations of the Third-Party Payment Processors & Facilitators. Lusid and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary, or otherwise, resulting from any error or failure on the part of the Third-Party Payment Processors.   <br/>
          Lusid does not have access to any bank login or password information, nor it can be held responsible for any breach of information caused by faulty programming or malicious users on the servers of the Service Providers or Third-Party Payment Processors.  <br/>  <br/>
          d. Failure of Automatic Payment - 
          Should automatic payment of any Subscription Fees or Payment Fees fail to occur for any reason, your account will be suspended, and Lusid will issue you an electronic invoice via email address on the account indicating that you must proceed manually, within a certain deadline date, with the full payment of the Fees as indicated on the invoice. Your account will be reactivated upon receipt of the payment.  <br/>  <br/>
          e. Modification of the Fees - 
          Lusid, in its sole discretion and at any time, may modify any Fees on written notice to you. Your continued use of the Platform after the Fees modification(s) comes into effect constitutes your agreement to pay the modified Fees at the modified rates. 
        </Typography>

        <Typography variant='h5'>8. Terms for the Payer</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          If you are a Payer making a payment for products or services through the platform, in addition to the other portions of these TOS that are applicable to you, you acknowledge and agree to the terms as outlined in this section of the TOS.<br/><br/>
          a. Acceptance to make the payment - 
          By clicking or tapping the acceptance box upon receiving the payment request: (i) you agree to pay the invoice amount; and (ii) you agree to abide by the additional terms that are presented to you upon acceptance, which are strictly between you and the Payee. Lusid is in no way a party to the legal relationship between you and the Payee. You are fully responsible for any and all loss, injuries, damages (pecuniary or otherwise), claims, liabilities and costs suffered by you, caused in whole or in part by the Payee, or any violation of the Payee Terms, and Lusid disclaims any responsibility thereto.<br/><br/>
          b. Payments Made Through the Platform - 
          As a Payer, you may be making a payment of an invoice or an online purchase to the Payee through the Platform. Payment of an invoice or an online purchase may be made via RTP, bank ACH transfer or any other electronic payment method at the sole discretion of the Lusid. If payment is made via ACH transfer, you will be required to log in to your online bank account, which is enabled by third party provider, Plaid. 
          Lusid, and payment facilitators including data access network partner Plaid and payment processor Orum, are unaffiliated companies and Lusid has no influence on the operations of the Payment Facilitators. Lusid and / or its Representatives cannot be held responsible for any losses or damages, direct or indirect, pecuniary, or otherwise, resulting from any error or failure on the part of the Payment Facilitators. <br/><br/>
          By using the Platform, you acknowledge and agree that: 
          <ul style={{ marginLeft: "40px" }}>
            <li>You understand that we work with our data access network partners to obtain your Account information.</li>
            <li>When you connect your Account, you are authorizing us to use your Account information to electronically confirm the validity of your Account with your financial institution. Once your Account is verified, you authorize Lusid to collect the following information: the name of the bank, your account number, and the routing number. All connection requests are subject to authorization by your financial institution.</li>
            <li>You represent and warrant that the referenced bank account belongs to you, and you have the authority to provide Lusid with this authorization to credit or debit your account. You authorize Lusid to arrange to electronically (1) debit your checking or savings account to pay for the invoice of goods and/or services you purchased from the Payee, and (2) process any returns, reversals, refunds, corrections, or other necessary adjustments, if necessary, by electronically debiting or crediting your account, as applicable. All connection and payment requests are subject to authorization by your financial institution.</li>
            <li>You agree that all transactions that you authorize comply with all applicable laws and regulations. You understand that this account authorization will remain in full force and effective until you inform Lusid at help@lusid.ai that you wish to revoke this authorization or revoke authorization in the account while logged in. You understand that Lusid requires at least five business days prior notice to cancel this authorization. </li>
            <li>When you connect your Account via Lusid, you are authorizing us to obtain other required information about your Account, to transfer funds out of your Account and to deposit them into the relevant Payee's account in the amount that you authorized. If you complete a purchase which sets up recurring payments, you authorize Lusid to arrange to transfer the funds from the Account and to deposit them to the relevant Payee's account for each recurring payment. You agree that Lusid may use the information you have provided to initiate and complete the payment process with the Payee.</li>
            <li>You are authorizing Lusid, and its Payment Processors or Facilitators, to make periodic debits, credit and / or process payments on behalf of the Payee and agree that Lusid is only acting as an agent for the Payee.</li>
            <li>You are authorizing the applicable Payment Processor or Facilitator to facilitate the payment(s) of an invoice or an online purchase from the authorized account. The amount of an invoice or an online purchase agreed between you and the Payee will be debited or processed by Lusid from the authorized bank account according to the payment terms with the Payee.</li>
            <li>Lusid does not provide any services other than the collection services pursuant to your dealings with the Payee and has no express or implied liability regarding the products or services provided by the Payee.</li>
            <li>“Lusid” may appear as the merchant for payments debited or processed from your linked bank account, and notwithstanding that fact, you agree that Lusid will not be held liable for any disputed transactions resulting in the non-supply of products or services by the Payee.</li>
            <li>You are authorizing Lusid to vary the amount of an invoice or an online purchase from time to time as may be agreed by you and the Payee, and to vary the amount of the payments upon receiving instructions from the Payee of the agreed variations. You agree that you do not require Lusid to notify you of such variation to debit the amount from the linked bank account.</li>
            <li>You must contact the Payee directly if you wish to alter/ stop/ cancel / dispute any debit or payment arrangement of an invoice or an online purchase.</li>
            <li>As payments are debited and credited electronically, it is your responsibility to ensure that there are sufficient cleared/ available funds in your nominated bank account to enable the debit to be honored by the debit date. If the authorized bank account has insufficient funds, you agree that Lusid will not be held responsible for any fees and charges that may be charged (including chargebacks) by your financial institution.</li>
            <li>If a debit or payment is returned to Lusid as unpaid, a failed payment fee may be payable to Lusid, and you will be responsible for any fees or charges applied for each unsuccessful debit attempt together with any collection fees and attorney’s fees as may be incurred by Lusid.</li>
            <li>In the event of any disputed payments, you must direct such disputes to the Payee and your financial institution. Lusid plays no role in adjudicating the dispute and denies any and all responsibility regarding such disputes between you and the Payee.</li>
            <li>In the event a claim is made, Lusid will not be liable for the refund of any funds or payments, and you agree to reimburse Lusid for any losses incurred because of any claim made against Lusid, further to the indemnification provision below in these TOS.</li>
            <li>You are also subject to the additional provisions regarding disputes as found in the next section of these TOS to the extent it applies to you.</li>
          </ul>
          <br/>
          c. Your Relationship with the Payee  - 
          When you are purchasing goods and/or services from a Payee, your purchase is governed by the Payee's terms and conditions offered and/or any contract between you and the Payee. You must contact the Payee to resolve any issues you have with respect to the goods and services you ordered or purchased from the Payee, including any issues relating to services, shipping and delivery, product warranty, product return, refund, exchange, and the terms of any agreements you entered with the Payee in connection with your order or purchase. You acknowledge and agree that Lusid is not responsible for any of these issues. <br/>
          You also acknowledge and agree that Lusid is not in any way responsible for the quality, usability, or any other element of the goods or services you order or purchase from a Payee. You acknowledge that Lusid does not endorse any goods or services offered by Payee. Lusid does not warrant the accuracy or reliability of any information or marketing messages contained in Payee websites.  
          You agree that we can make information about your Account and payments available to the Payee through a Payee dashboard and/or our API to fulfill your payment requests, each of which will require the Payee to pass certain authentication measures.
        </Typography>

        <Typography variant='h5'>9. TOS for the Payee</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          If you are a Payee receiving a payment for the products or services through the platform, in addition to the other portions of these TOS that are applicable to you, you acknowledge and agree to the terms as outlined in this section of the TOS.<br/><br/>
          a. Acceptance to Receive the Payments - 
          By clicking or tapping to accept or receive the payment, you agree to abide by the additional terms that are presented by you to the Payer, which are strictly between you and the Payer. Lusid is in no way a party to the legal relationship between you and the Payer. You are fully responsible for any and all loss, injuries, damages (pecuniary or otherwise), claims, liabilities and costs suffered by you, caused in whole or in part by the Payer, or any violation of the Payer Terms, and Lusid disclaims any responsibility thereto.<br/><br/>
          b. Payments Made Through the Platform - 
          As a Payee, you may be receiving a payment of an invoice or an online purchase from the Payer through the Platform. To receive the payments, you will be required to connect your bank account, which is enabled by the third-party, Plaid. <br/>
          Lusid and the Payment Facilitators are unaffiliated companies and Lusid has no influence on the operations of the Payment Facilitators. Lusid and / or its Representatives cannot be held responsible for any losses or damages, direct or indirect, pecuniary, or otherwise, resulting from any error or failure on the part of the Payment Facilitators. <br/><br/>
          By using the Platform, you acknowledge and agree that: 
          <ul style={{ marginLeft: "40px" }}>
            <li>You understand that we work with our data access network partners to obtain your and your Payer's Account information.</li>
            <li>When you connect your Account, you are authorizing us to use your Account information to electronically confirm the validity of your Account with your financial institution. Once your Account is verified, you authorize Lusid to collect the following information: the name of your bank, your account number, and the routing number. All connection requests are subject to authorization by your financial institution. You authorize us to obtain other required information about your Account if needed, to transfer the funds to your Account. If recurring payments are set up, you authorize us to arrange the funds to be deposited to your authorized account for each recurring payment.</li>
            <li>You represent and warrant that the referenced bank account belongs to you, and you have the authority to provide Lusid with this authorization to credit or debit your account. You authorize Lusid to arrange to electronically (1) deposit the payment to your authorized Account, and (2) process any returns, reversals, refunds, corrections, or other necessary adjustments, if necessary, by electronically debiting or crediting your linked account, as applicable. All connections and payment requests are subject to authorization by your financial institution.</li>
            <li>You agree that all transactions that you authorize comply with all applicable laws and regulations. You understand that this account authorization will remain in full force and effective until you inform Lusid at help@lusid.ai that you wish to revoke this authorization or revoke authorization in the account while logged in. You understand that Lusid requires at least five business days prior notice to cancel this authorization.</li>
            <li>You are authorizing Lusid, via the Payment Processors or Facilitators, to debit/credit your linked or authorized account for any applicable and/ or subscription fees.</li>
            <li>You are authorizing the applicable Payment Processor or Facilitator to facilitate the payment(s) of an invoice or an online purchase.</li>
            <li>Lusid does not provide any services other than the collection services pursuant to your dealings with the Payer and has no express or implied liability regarding the products or services provided by you.</li>
            <li>The amount of an invoice or an online purchase agreed between you and the Payer will be credited by Lusid to your linked or authorized account.</li>
            <li>“Lusid” may appear as the merchant for payments processed in your account, and notwithstanding that fact, you agree that Lusid will not be held liable for any disputed transactions resulting in the non-supply of products or services.</li>
            <li>You agree and are authorizing Lusid to vary the amount of the payments upon receiving instructions from you of the agreed variations. You agree that Lusid is not responsible for any such variations as agreed upon by you and your Payer and do not require Lusid to notify you or your Payer of such variation.</li>
            <li>Your Payer will contact you directly if the Payer wishes to alter/ stop/ cancel any debit or payment arrangement of an invoice or an online purchase. You agree that Lusid is not responsible for any such request/ decision as agreed upon by you and your Payer.</li>
            <li>As payments are debited and credited electronically, you agree that Lusid will not be held responsible for any fees and charges that may be charged (including chargebacks) by your or your Payer’s financial institution.</li>
            <li>If a debit or payment is returned to Lusid as unpaid, a failed payment fee may be payable to Lusid, and you will be responsible for any fees or charges applied for each unsuccessful debit attempt together with any collection fees and attorney’s fees as may be incurred by Lusid.</li>
            <li>All disputes regarding an invoice or an online purchase (whether between Payee and the Payer, or you and a financial institution and/ or Payment Facilitator) will be directed to you as Lusid is acting only as an agent. You agree that Lusid will not be held responsible for any fees and charges that may be charged (including chargebacks) by your financial institution.</li>
            <li>In the event of any disputed payments, you must directly discuss it with your Payer and/ or your financial institution. You agree that Lusid will not be held liable for any disputed transactions.</li>
            <li>In the event a claim is made, Lusid will not be liable for the refund of any funds or payments, and you agree to reimburse Lusid for any losses incurred because of any claim made against Lusid, further to the indemnification provision below in these TOS.</li>
            <li>You are also subject to the additional provisions regarding disputes as found in the next section of these TOS to the extent it applies to you.</li>
          </ul>
          <br/>
          c. Your Relationship with the Payer - 
          When you are selling goods and/or services, the purchase is governed by your terms and conditions offered, and/or any contract between you and the Payer. You must contact the Payer to resolve any issues the Payer may have with respect to the goods and services ordered or purchased, including any issues relating to shipping and delivery, product warranty, product return, refund, exchange, and the terms of any agreements you entered with the Payee in connection with your order or purchase. You acknowledge and agree that Lusid is not responsible for any of these issues. <br/>
          You, the Payee, also acknowledge and agree that Lusid is not in any way responsible for the quality, usability, or any other element of the goods or services offered by you. You acknowledge that Lusid does not endorse any goods or services you offer or linked to the Platform. Lusid does not warrant the accuracy or reliability of any information or marketing messages contained on your websites linked to the Lusid Platform.<br/><br/>
          d. Withholding payment of an invoice or an online purchase - 
          You acknowledge and agree that Lusid retains the absolute discretion to withhold remitting the payment to you for any legitimate reason, including but not limited to: (i) the value of the invoice or an online purchase payment appears excessive; or (ii) there is an indication that the payments of an invoice or an online purchase is being made fraudulently or for illegal reasons. In the event Lusid withholds remittance of the payment in this manner, you may be informed of the withholding and the reasoning and provided the opportunity to explain and / or rectify the situation in order for us to release the payment. However, you acknowledge and agree that Lusid’s ultimate decision to release or withhold the payment is final. <br/>
          Without limiting the generality of the Disclaimer of Warranties further in these TOS, Lusid and / or its Representatives will not be responsible for any losses or damages, pecuniary or otherwise, to you resulting from Lusid’s refusal to remit a payment to you, and Lusid disclaims any responsibility thereto.<br/><br/>
          e. Disputes - 
          You acknowledge and agree that in the event a Payer disputes a payment of an invoice or an online purchase, Lusid plays no role in adjudicating the dispute between the two parties and denies any and all responsibility regarding such disputes. You must reach out to the Payer directly to resolve the reason for the dispute, and to resolve and settle these disputes directly with the Payer.  
          Notwithstanding the foregoing, you acknowledge and agree that Lusid retains the absolute discretion to immediately debit your bank account of the disputed amount.  <br/>
          Without limiting the generality of the Disclaimer of Warranties further in these TOS, Lusid and / or its Representatives will not be responsible for any losses or damages, pecuniary or otherwise, to you resulting from any dispute over a payment between a Payee and the Payer, and Lusid disclaims any responsibility thereto.
        </Typography>

        <Typography variant='h5'>10. Proprietary and Intellectual Property Rights; Your Data</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          a. Lusid’s Rights - 
          You acknowledge that: (i) the Website and Platform contain proprietary and confidential information that are protected by applicable intellectual property laws and other laws, and (ii) Lusid and/or third parties (via license) own all the rights, title and interest in and to the Website and Platform and content that may be presented or accessed through the Website and Platform (except for Data as defined below), including without limitation all Intellectual Property Rights therein and thereto. All rights, not specifically granted under these TOS, are reserved to Lusid and its licensors. “Intellectual Property Rights'' means any and all rights existing from time to time under patent law, copyright law, trade secret law, trademark law, unfair competition law, and any and all other proprietary rights, and any and all applications, renewals, extensions and restorations thereof, now or hereafter in force and effect worldwide, whether registered or not. <br/>
          You agree that you will not, and will not allow any third party, to (i) copy, sell, license, distribute, transfer, modify, adapt, translate, prepare derivative works from, decompile, reverse engineer, disassemble or otherwise attempt to derive source code from the Website or Platform, or content that may be presented or accessed through the Website or Platform for any purpose, unless otherwise permitted by these TOS or the functionalities of the Website or Platform; (ii) take any action to circumvent or defeat the security deployed or enforced by any functionality contained in or on the Website and Platform; or (iii) remove, obscure, or alter Lusid’s or any third party’s copyright notices, trademarks, or other proprietary rights notices affixed to or contained within or accessed in conjunction with or through the Website and Platform.  <br/>
          The content, arrangement and layout of the Website and Platform, including but not limited to the trademarks, photos, logos, videos, audio, images, text (in the form of plain text, HTML, or PDFs) and Computer Code are proprietary to Lusid, either owned or under license, and may not be copied, imitated, reproduced, displayed, distributed, transmitted, decompiled or otherwise used without the express permission of Lusid, or as permitted by the functionality of the Website and Platform or these TOS. Any unauthorized use of the content, arrangement or layout of the Website or Platform, Computer Code, images, logos, videos, audio or trademarks found on or in the Website or Platform or any portion of or derivative works thereof may violate civil or criminal laws, including but not limited to intellectual property laws, and Lusid may take action accordingly.  <br/>
          The above paragraph further applies to third party property used as part of the Website and Platform, including but not limited to third party Computer Code.  For the purposes of the present section, “Computer Code” includes but is not limited to source code in any programming language, object code, frameworks, CSS, Python, JavaScript or similar files, templates, modules, or any similar files, and related documentation.  <br/> <br/>
          b. Data - 
          All data you submit through the Platform as a Logged-in User whether in the form of a text, financial information, graphics, images, or any other form of inputted information, including any information about your Payers, and all their associated Intellectual Property Rights (collectively “Data”) remain your property, and any documents created from your inputted Data, and their related Intellectual Property Rights, are the property of Lusid; however, Lusid grants you a worldwide, exclusive, royalty-free, sub-licensable and transferable license to use, edit, translate, reproduce, distribute, and display those documents.  <br/>
          Your access to the inputted Data is contingent upon keeping your account in good standing with Lusid. You are expected to maintain copies of all Data inputted into the Platform. Lusid adheres to its best policies and procedures to prevent data loss, including a daily system data back-up regime, but does not make any guarantees that there will be no loss of Data. Furthermore, Lusid is under no obligation to retain copies of your Data following termination of your account for any reason. <br/>
          Without limiting the generality of the disclaimers found further in these TOS, Lusid and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary, or otherwise, resulting from any loss of Data no matter how caused, or Lusid’s deletion of your Data following the termination of your account, and Lusid disclaims any responsibility thereto. <br/>
          Due to financial regulations, once you’ve initiated the business verification process, we are required to retain certain personal and business information, including business transactions, even if the account is inactive, suspended, or closed. This information will be kept on file for a minimum of 7 years to comply with legal and regulatory obligations. <br/> <br/>
          c. Access to Your Data by Third Party Applications - 
          If you enable third-party integrations (e.g. QuickBooks Online, Xero, Sage Business Cloud Etc.) for use in connection with and within the Platform, you acknowledge and agree that Lusid may allow providers of those third-party applications to access your Data as required for the interoperation of such third-party applications with the Platform. Without limiting the generality of the disclaimers found further in these TOS, Lusid and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary, or otherwise, resulting or arising from any disclosure, modification or deletion of your Data resulting from any such access by the providers of such third-party applications. <br/> <br/>
          d. Submitted Information - 
          If you choose to communicate to Lusid (via any means) suggestions for improvements to the Website or Platform or any idea or proposal related to Lusid or its businesses or properties (collectively, “Feedback”), Lusid shall own all the rights, title, and interest in and to the Feedback and will be entitled to use the Feedback without restriction. You hereby irrevocably assign all right, title and interest in and to the Feedback to Lusid and waive in favor of Lusid, its successors and assigns all your moral rights in the Feedback; and agree to provide Lusid such assistance as Lusid may require to document, perfect, and maintain Lusid’s rights to the Feedback. You acknowledge and agree that, by providing any Feedback to Lusid, you are not entitled to any compensation or reimbursement of any kind from Lusid under any circumstances. 
        </Typography>

        <Typography variant='h5'>11. External Links</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>   
          From time-to-time Lusid or other Logged-in Users may provide links to other websites or services. Links from the Website or Platform may take you to websites or services not covered by these TOS. When you access third party resources on the Internet in this manner, you do so at your own risk. Lusid or other Logged-in Users provides those links as a convenience to you and Lusid takes no responsibility for your use of those other websites or services or protection of your privacy (including collection of your personal information) on those other websites or services. We encourage you to check the Terms of Service and / or Privacy Policy of any website or service you visit. Lusid does not make any claim or warranty whatsoever about the content of those websites or services to which we link, or any products or services available through those websites or the third parties operating those websites. <br/>
          In no way will Lusid be held responsible for any damages, direct or indirect, pecuniary or non-pecuniary: (1) arising from your use of websites or other services that may be linked to from the Website or Platform or the information thereon; (2) arising from any virus, Trojan horse, worm or other similar destructive file received as a result of your use of those websites or services; (3) caused by or in connection with, use of or reliance on any content, or products or services (whether free or for purchase) available on or through any linked-to website or service; or (4) arising from the actions of the operators of any such website or service. 
        </Typography>

        <Typography variant='h5'>12. Interruption of the Website or Platform</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          From time to time, the Website or Platform may be unavailable for periods of time for maintenance and/ or modifications to the Website or Platform. While we will try our best making this unavailability as brief/ seamless as possible, Lusid will not be held liable for any losses or damages, pecuniary or non-pecuniary, resulting from the interruption of the normal functioning of the Website or Platform, and disclaims any responsibility thereto, including outages due to planned or unplanned maintenance or other issues. 
        </Typography>

        <Typography variant='h5'>13. Termination of Your Access to the Website or Platform</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          You agree that Lusid, in their sole discretion, with or without cause, has the right (but not the obligation) to block your IP address, revoke your account credentials, or otherwise terminate your access to or use of the Website or Platform (or any portion thereof), immediately and without notice, if Lusid believes that you have acted inconsistently with the TOS, or otherwise acted improperly towards Lusid or its Representatives.<br/>
          You may also end your access to the Platform by canceling your Account when you are a Logged-in User or emailing help@lusid.ai. <br/>
          Lusid may also, in their sole discretion and at any time, discontinue providing the Website or Platform, or any part thereof, with or without notice. You agree that Lusid will not be liable to you or any third party for any losses or damages, pecuniary or non-pecuniary, resulting from termination of your access to the Website or Platform, or from Lusid’s termination of the Website or Platform or any part thereof. <br/>
          Termination of the Website or Platform or your access to the Website or Platform will terminate the present TOS between you and Lusid. All provisions of these TOS, which by their nature should survive termination of these TOS will survive termination, including without limitation, intellectual property provisions, disclaimers, indemnity, and limitations of liability. 
        </Typography>

        <Typography variant='h5'>14. Disclaimer of Warranties</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          You expressly understand and agree that your use of the Website and Platform, the information thereon (whether provided by Lusid or third parties) or any activity arising from your use of the Website or Platform or the information thereon or the materials downloaded therefrom is at your sole risk. The Website, the Platform, any materials downloaded therefrom, or any third party materials, are provided on an "as is" and "as available" basis, and you will be solely responsible for any damage to your computer system or loss of data that results from the download, stream or access of any material obtained through the use of the Website or Platform or any other functionalities of the Website or Platform, or losses or damages (financial or otherwise) resulting from your use of the Website or Platform, the information thereon, any materials downloaded therefrom, or any activity arising from the use of the Platform (including but not limited to your provision of, or use of, Professional Platform), the information thereon or any materials downloaded therefrom.<br/><br/>
          The information or resources provided through the Website and Platform, written, or produced by Lusid staff, freelance writers or other subcontractors hired by Lusid are expected to be as accurate as possible at the time of writing or production, and every effort has been made to ensure that the information from the Website and Platform is as accurate and up to date as possible. However, certain information may change, and errors or omissions may occur. Lusid will not be responsible for any loss or damage, financial or otherwise, resulting from changes or errors in information, or any omission, on the Website or Platform or the information thereon, or your reliance on such information whether the information is correct or not.<br/><br/>
          Lusid expressly disclaims all warranties of any kind, whether express or implied, including but not limited to warranties of title and non-infringement; warranties that the Website, the Platform, the information thereon or any materials downloaded therefrom, and any third-party materials will be uninterrupted, error-free, accurate, reliable, and free from virus and other harmful components; and the implied warranties of merchantability and fitness for a particular purpose. Lusid, its affiliates and their respective Representatives, do not warrant that: (i) the Website and Platform will be secure or available at any particular time or location; (ii) any defects or errors or omissions will be corrected; (iii) any content (whether provided by Lusid or third parties) available on or through the Website and Platform are free of viruses or other harmful components; (iv) functionalities of the Website and Platform will work equally in desktop and handheld or tablet device environments; or (v) the results of using the Website and Platform or any activity arising therefrom (including but not limited to your provision of, or use of, Professional Platform), or any content downloaded therefrom will meet your requirements, whether business needs or otherwise.<br/><br/>
          Some of the content displayed on the Website or through the Platform may include elements that belong to or are provided by third parties, including Users’. You acknowledge that Lusid assumes no responsibility for such content. 
          To the extent that the law does not permit a disclaimer of warranties, all content accessible on the Website and Platform, or any other website to which we link, and all operations on the Website and Platform are warranted only to the minimum amount legally required. 
        </Typography>

        <Typography variant='h5'>15. Limitation of Liability</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          In no case will Lusid and/ or its Representatives be liable for any indirect, special, consequential, exemplary, punitive damages or other damages, or for any losses, damages, liabilities, costs and expenses arising out of or relating to (i) your access, use, misuse or inability to access or use the Website or Platform, or (ii) the interruption, suspension or termination of any part of or all the Website or Platform; and in both cases (i) and (ii) regardless of the cause of action (whether in contract, warranty, delict, quasi-delict, tort, negligence, strict liability or any other theory of liability) and even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary in the TOS, in no event will our aggregate liability for any claims in connection with your use of the Website or Platform exceed the amount of US $100.<br/><br/>
          You expressly understand and agree that Lusid or its Representatives shall not be liable for any direct, indirect, incidental, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, in contract, tort, strict liability, negligence, general civil liability or any other cause of action under legal or equitable theory, relating to the Website or Platform, the information on the Website or Platform, your use of the Website or Platform, activities arising from your use of the Website or Platform (including but not limited to your provision of, or use of, Professional Platform), any third party materials on the Website or Platform, or any materials downloaded from the Website or Platform. This limitation of liability applies, without limitation, to any damages or injury caused by any error, omission, or other failure of performance by Lusid or its Representatives; any interruption, defect or delay in operation or transmission, including communication line failure; any computer virus; and any theft, destruction, or alteration of, or unauthorized access to or use of, any electronic records.<br/><br/>
          Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above exclusions and limitations may or may not apply to you. 
        </Typography>

        <Typography variant='h5'>16. Indemnity</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          Notwithstanding any other term of the TOS or any act or failure to act by Lusid or its Representatives, you agree to indemnify, defend and hold harmless Lusid and its Representatives where applicable, from and against any damages, liabilities, costs and expenses (including attorneys’ fees), claims or demands, arising out of (i) your use of or connection to the Website or Platform, the information thereon, the information downloaded therefrom; (ii) your participation in any activities arising from the Website or Platform (including but not limited to your provision of, or use of, Professional Platform) or the information thereon; (iii) your violation of, or failure to perform your obligations under the TOS or the Privacy Policy; or (iv) your violation of any rights of a third party. 
        </Typography>

        <Typography variant='h5'>17. Governing Law and Applicable Jurisdiction</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          These TOS and your use of the Website or Platform will be governed by and will be construed under the laws of the State of Delaware, without regard to the conflicts of law’s provisions thereof. These TOS may not be transferred or assigned by you without prior written consent of Lusid. Lusid may assign or transfer this TOS to its affiliates or in connection with a merger, sale, reorganization, or other change of control. In addition, Lusid's affiliates, contractors and service providers may exercise Lusid's rights or fulfil its obligations under this TOS. Waivers must be in writing and no waivers will be implied.<br/><br/>
          Notwithstanding the foregoing, Lusid have the sole discretion and right to bring legal action against you in either the Delaware courts or in which you reside or are located: (i) to seek injunctive relief against you; (ii) to obtain a judgment against you whether a judgment by the Delaware court will, or may not be, enforced by the jurisdiction in which you reside or are located; or (iii) to enforce in your jurisdiction a judgment obtained against you in the Delaware court.<br/><br/>
          You represent and warrant that to the extent you are acquiring the right to access and use the Platform and agreeing to these TOS for the purposes of a business or Legal Entity and that, to the maximum extent permitted by law, any statutory consumer guarantees or legislation intended to protect non-business consumers in any jurisdiction does not apply to the supply of the Platform, the Website or these TOS. 
        </Typography>

        <Typography variant='h5'>18. ACH Origination Agreement</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          The ACH Network allows you to collect funds through debit or credit Transactions from US bank account holders. Terms specifically applicable to initiation of ACH Network Transactions are stated below. As with all Payment Methods, your use of Lusid products that provide access to the ACH Network via submission of ACH Debit and ACH Credit entries, bind you to these ACH Payment Terms (these “Terms”) as part of the Lusid Services Agreement. You accept and agree to comply with all of these terms.<br/><br/>
          Nacha Rules: When submitting Transactions over the ACH Network, you must comply with the Nacha Operating Rules.<br/>
          ACH Entry Authorizations: When you send or collect funds with ACH credit and debit entries via the Lusid Services, Lusid is operating as a Third Party Sender and you understand and accept your role as the Originator (as defined in the Nacha Operating Rules), including to authorize the banks that Lusid’s ACH Service Provider uses to enable the submission of Transactions over the ACH Network (“ODFI Banks”) on your behalf to and from your Customer’s bank accounts. <br/>
          When you use Lusid to initiate ACH Network Transactions with your Customers, you represent and warrant that: (a) you have all necessary authorizations and approvals from your Customers for Lusid to transmit an ACH Network debit or credit Transaction to the Customer’s bank account and (b) the information you provide Lusid about each ACH Network Transaction is accurate, timely and complete. Lusid is not liable for any return, reversal or other failure (or any related costs) arising from your acts and omissions, including failure to obtain your Customer’s authorization for an ACH Network Transaction.<br/>
          Timing of Funds, Limits and Charges: Lusid and its ACH Service Provider will make funds available as per settlement timings agreed to in relation to the type of ACH transfer selected. Lusid may require separate or pass through origination limits as set up by the ACH Service Provider. Charges for your origination of ACH, returns and other ACH related services will be billed to you by the Lusid.<br/>
          Termination and Suspension: Without limiting Lusid’s rights to terminate or suspend your access to the Services under the Lusid Services Agreement, Lusid may also terminate or suspend your access to the ACH Network for your violation of the Nacha Operating Rules, including if an ODFI Bank requires your suspension or termination, or if you exceed acceptable limits on returns.<br/>
          Requests for Compliance Information: By collecting funds via ACH debit Transactions and as required by the Nacha Operating Rules, you will provide information and documentation requested by Lusid, Lusid’s ACH Service Provider, or ODFI Banks for the purpose of ensuring your compliance with these Terms and the Nacha Operating Rules upon reasonable notice. You agree to cooperate with any request for information necessary to complete ACH risk assessments and audits in a timely manner. Lusid may suspend or terminate your access to the ACH Network immediately if you fail to provide information or documentation requested.<br/>
          Warranty: You warrant to Lusid, as an Originator of Transactions made under the Nacha Operating Rules, that no Transaction delivered to Lusid, will cause Lusid, its ACH Service Providers, or ODFI Banks to be in violation of any regulation or sanction administered by a Governmental Authority or otherwise cause Lusid, its ACH Service Providers, or ODFI Banks to be in violation of an laws of the United States.<br/>
          Nested Third Party Senders: You must not submit ACH Network Transactions as a Nested Third Party Sender (as defined in the Nacha Operating Rules) through the Lusid Payments Services.<br/>
          ACH Debit Authorization: By linking a bank account at Lusid platform, you authorize Lusid to debit or credit your bank account via ACH as per our Terms of Service. You authorize, if necessary, adjustments for any debit entries made in error to your account. This authority will remain in effect until your account has been terminated or you update your payment preferences, or we are notified by you in writing to cancel it in such time as to afford commercially reasonable opportunity to act on it. You represent and warrant that linked bank account details referenced therein belong to you and you have the authority to provide this authorization to credit or debit your account.<br/>
        </Typography>
        
        <Typography variant='h5'>19. Miscellaneous Provisions</Typography>
        <Typography variant='body1' mb={"40px"} sx={{ color: "grey" }}>
          The Agreement<br/>
          The TOS, in conjunction with the Privacy Policy, constitutes the entire agreement between you and Lusid with respect to your use of the Website and Platform, superseding any prior agreements between you and Lusid (including but not limited to previous versions of the Terms of Service.)<br/><br/>
          Delays<br/>
          Lusid will not be liable for any failure to perform its obligations under the present TOS where such failure results from any cause beyond Lusid’s reasonable control, including, but not limited to, mechanical, electronic or communications failure or degradation, or any force majeure event.<br/><br/>
          Severability<br/>
          If any provision of the TOS or Privacy Policy is found by a court of competent jurisdiction to be invalid, you and Lusid nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the TOS or Privacy Policy, as the case may be, will remain in full force and effect.<br/><br/>
          Waiver<br/>
          The failure of Lusid to exercise or enforce any right or provision of these TOS does not constitute a waiver of such right or provision, which will still be available to Lusid.<br/><br/>
          Notices<br/>
          Any notice required to be given under these TOS by either party to the other must be emailed and will be deemed to have been given on transmission. Notices to Lusid must be sent to help@lusid.ai or to any other email address notified by email to you by Lusid. Notices to you will be sent to the email address associated with your account.<br/><br/>
          Headings<br/>
          The section or sub-section titles in the TOS and Privacy Policy are for convenience only and have no legal or contractual effect.
        </Typography>
      </Box>
		</Container>
  )
}

export default TermsAndConditionsPage;