/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { 
	Box,
	Button,
	Container, 
	Grid, 
	TextField, 
	Typography 
} from '@mui/material';

import { 
  CreateContactRequest
} from '../../redux/actions/contactRequestsActions';

import styles from "../../global.scss";
import "./styles.scss";

const Data = {
  Name: "",
  Email: "",
  Company: "",
  Message: ""
}

const ContactPage = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(Data);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    
  }, []);

  // ---------------------- handle change -----------------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  } 

  // ---------------------- handle submit -----------------------
  const handleSubmit = () => { 
    console.log(formData);
    dispatch(CreateContactRequest(formData, setLoading))
      .then(() => {
        setFormData(Data);
      })
  }


  return (
    <Container maxWidth="xl">
      <Box className="contact">
        {/* <Box sx={{ margin: "15px 0px 100px 0px", borderRadius: "30px", backgroundColor: styles["primary_light"], height: "250px" }} 
          className="flexCenterCenterRow">
					<Typography variant='h3'>Contact us</Typography>
        </Box> */}

        <Grid 
          container
          columnSpacing={2}
          rowSpacing={6}
          sx={{ width: "100%", marginTop: "60px" }}
          className="flexFSSBRow">
          <Grid 
            item
            xs={12} sm={12} md={7} lg={7}>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d166654.50707588316!2d-77.46991091240595!3d38.87789542474335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64ee06437c18f%3A0x4e78ed46c00ee5bd!2s11166%20Fairfax%20Blvd%20Suite%20500%2C%20Fairfax%2C%20VA%2022030%2C%20USA!5e0!3m2!1sen!2sin!4v1705556733082!5m2!1sen!2sin" 
              width="600" height="450" 
              frameBorder={0}
              className='contact__map'
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade" 
            />
          </Grid>
          <Grid 
            item
            xs={12} sm={12} md={5} lg={5}>
            <Typography variant='h5' textAlign={"center"} mb={"40px"}>Contact us</Typography>
            <Box
              className="flexCenterCenterColumn" 
              sx={{ 
                width: "100%", 
                gap: "20px",
                backgroundColor: styles["primary_light"], 
                padding: "30px 27px", 
                borderRadius: "20px" }}>
              <TextField 
                variant='outlined'
                placeholder='Enter your name'
                label="Name *"
                name='Name'
                value={formData.Name}
                onChange={handleChange}
                fullWidth
                />
              <TextField 
                variant='outlined'
                placeholder='Enter your email address'
                label="Email *"
                name='Email'
                value={formData.Email}
                onChange={handleChange}
                fullWidth
                />
              <TextField 
                variant='outlined'
                placeholder='Enter your company name'
                label="Company"
                name='Company'
                value={formData.Company}
                onChange={handleChange}
                fullWidth
                />
              <TextField 
                variant='outlined'
                placeholder='Your message'
                label="Message"
                name='Message'
                value={formData.Message}
                onChange={handleChange}
                fullWidth
                />
              <Button
                variant='contained'
                sx={{ width: "100%" }}
                disabled={
                  !(formData.Name !== "" 
                    && formData.Email !== "")
                }
                onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ContactPage