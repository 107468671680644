import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
	Box,
	Button,
	Container, 
	Grid, 
	Link, 
	Typography 
} from '@mui/material';

import anime from 'animejs/lib/anime.es.js';

// import Logo from "../../assets/icons/mini-logo-blue.png";
import HomeIcon from "../../assets/images/home2.jpg";

import SolutionImg from "../../assets/images/solution2.jpg";
import SS from "../../assets/ss.png";
// import EastIcon from '@mui/icons-material/East';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import MonitorHeartTwoToneIcon from '@mui/icons-material/MonitorHeartTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CableTwoToneIcon from '@mui/icons-material/CableTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';

import styles from "../../global.scss";
import "./styles.scss";

const HomePage = () => {
	const navigate = useNavigate();

	const location = useLocation();

  useEffect(() => {
    if(location.hash) handleScrollToView(location.hash.slice(1))
  }, [location]);

  // ------------------ miscellaneous -------------------
  const handleScrollToView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  } 

	useEffect(() => {
		var timeLine1 = anime.timeline({
			easing: 'easeOutExpo',
			loop: true
		});

		timeLine1
		.add({
			targets: "#text1",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text2",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text3",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text4",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})
		.add({
			targets: "#text5",
			keyframes: [
				{ height: "60px", duration: 200 },
				{ height: "0px", duration: 200, delay: 2000 },
			]
		})

	}, []);

	// ------------------- miscellaneous -------------------
	const handleNavigate = (route) => {
		document.body.scrollTop = 0; // For Safari
  	document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		navigate(route);
	}

  return (
		<Box className="home">
			<Container 
				maxWidth="xl">
				<Box className="home__top flexCenterSBRow">
					<Box className={"flex_CenterColumn"} 
						sx={{ 
							margin: { xs: "auto", sm: "auto", md: "0px", lg: "0px" },
							height: "80vh", 
							position: "relative"
						}}>
						<Typography 
							sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}>Operating system for<br/>
								Buyers & Suppliers.</Typography>

						{/* <Box sx={{ height: "60px" }}>
							<Typography id="text1" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Spend Automation</Typography>
							<Typography id="text2" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Virtual Cards</Typography>
							<Typography id="text3" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Tokenization</Typography>
							<Typography id="text4" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Biometrics</Typography>
							<Typography id="text5" className='home__text' 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}
								>Hyper-personalization</Typography>
						</Box> */}
{/* 
						<Typography variant='subtitle2'
							sx={{ color: "grey", marginTop: "30px"}}>
							A frictionless platform built for success of small and
								medium businesses. 
						</Typography> */}

						<Typography variant='subtitle2'
							sx={{ color: "grey", marginTop: "10px"}}>
							<br/>
							Maximize cashflow through a fully integrated Accounts
							Payables, <br/>Accounts Receivables, 
							Expense management, and advanced spend controls.
						</Typography>

						{/* <Typography variant='body1'
							sx={{ color: "grey", marginTop: "30px"}}>
								<ul>
									<li>Manage &amp; pay Suppliers effortlessly</li>
		<li>Collect payments from Buyers</li>
		<li>Track and optimize DPO.</li>
								</ul>
								</Typography> */}

						


						<Box className="flexCenter_Row"
							sx={{ gap: "30px", marginTop: "60px" }}>
							<Button
								variant="contained"
								onClick={() => handleNavigate("/contact-us")}>
								Contact sales
							</Button>
							<Button
								variant="outlined"
								onClick={() => handleNavigate("/products")}>
								Learn more
							</Button>
						</Box>

					</Box>
					<Box 
						className="flexCenterCenterRow" 
						sx={{ width: { xs: "0%", sm: "0%", md: "60%", lg: "60%"},
							backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
						<img
							src={HomeIcon}
							alt="home-icon"
							style={{width: "100%", height: "100%", objectFit: "fill" }}
							/>
					</Box>
				</Box>
			</Container>

				
				<Box
					marginBottom={"100px"}>
					<Container
						maxWidth="lg">
						<Typography
							textAlign={"center"}
							variant='h5'
							color={"grey"}
							marginBottom={"100px"}>
							Frictionless platform for small & medium businesses.
						</Typography>

						<Typography
							textAlign={"center"}
							variant='h4'
							marginBottom={"20px"}>
							Pay & get paid
						</Typography>

						<img
							src={SS}
							alt='tablet-ss'
							width={"100%"}
							/>

						<Grid container spacing={2} sx={{ marginTop: "60px"}}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<AccountBalanceTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Pay with Account transfers</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Make payments using
standard ACH for free
or opt for same-day
ACH or instant
transfer.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<CreditCardTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Bill Payment by Card</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Make card payments
to vendors, regardless
of whether they accept
cards or not.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<DescriptionTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Receivable Invoices with options</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Generate and dispatch
invoices to customers
using various delivery
options.
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className=" flexFSSBRow" gap="15px">
									<LocalAtmTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px" }} mt={"5px"}>
										<Typography variant='h6'>Skip check deposits entirely</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
										Get payments directly
deposited into your
bank account from
customers.
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>

					</Container>
				</Box>

			<Box>
				<Container
						maxWidth="xl">
								
				</Container>
			</Box>

			<Box className="home__products">
				<Container
					maxWidth="xl">
					<Typography variant='h4' className='home__links'
						textAlign={"center"}
						onClick={() => handleNavigate("/products")}>Manage your cash

						inflows & outflows.</Typography>

						{/* <Typography variant='body1'
							sx={{ color: "grey", marginTop: "30px"}}>
								<ul>
									<li>Manage &amp; pay Suppliers effortlessly</li>
		<li>Collect payments from Buyers</li>
		<li>Track and optimize DPO.</li>
								</ul>
								</Typography> */}
					

<Typography
	variant='h5'
	color={"grey"}
	marginTop={"40px"}
	textAlign={"center"}
	marginBottom={"40px"}
	>


One platform fully integrated to provide advanced money movement options and

access to working capital.
					</Typography>

					{/* <Typography variant='body2' sx={{ color: "grey", marginBottom: "20px" }}>
						Lusid is on a mission to shake up the industry with its innovative range of Consumer and Business products. Whether you're a business owner looking to boost your brand or a consumer on the hunt for the next big thing, Lusid has got you covered.
					</Typography>
					<Typography variant='body2' sx={{ color: "grey", marginBottom: "20px" }}>
						Lusid offers an array of Consumer products that are sure to catch the eye of consumers everywhere. With a finger on the pulse of consumer trends, Lusid brings you products that are not only functional but also oh-so-cool. 
					</Typography>
					<Typography variant='body2' sx={{ color: "grey", marginBottom: "20px" }}>
						But that's not all! For all you business owners out there, Lusid offers a comprehensive suite of Business solutions tailored to meet your every payment need. Our products can be personalized that suit your unique business goals and objectives.
					</Typography> */}

					{/* <Button variant='outlined'
						onClick={() => handleNavigate("/products")}>Learn more</Button> */}
					
					<Grid container spacing={4} sx={{ marginTop: "40px"}}>
						<Grid item xs={12} sm={6} md lg>
							<Box className="home__products-cards flexCenterSBColumn"
								onClick={() => navigate("/products#web-app")}>
								<Box className="flexCenterCenterColumn" sx={{ gap: "40px", height: "calc(100% - 50px)" }}>
									{/* <img	
										src={Logo}
										alt="web-logo"
										style={{ height: "50%"}}
										/> */}
									<DesktopWindowsRoundedIcon sx={{ color: styles["secondary"], fontSize: "120px" }} />
									<Typography variant='subtitle1'>Lusid Web App</Typography>
								</Box>

								{/* <Box 
									sx={{ borderRadius: "8px", gap: "10px", backgroundColor: "white", padding: "10px", width: "100%" }} 
									className="flexCenterCenterRow">
									<LanguageIcon />	
									<Typography variant='body1'>
										Sign In
									</Typography>
								</Box> */}
								{/* <Box 
									sx={{ borderRadius: "8px", gap: "10px", backgroundColor: "white", padding: "10px", width: "100%" }} 
									className="flexCenterCenterRow"
									onClick={(e) => { e.stopPropagation() }}>
									<Link
										href="https://business.lusid.ai/auth"
										target="_blank" rel="noopener noreferrer"
										sx={{ gap: "10px", textDecoration: "none", color: styles["secondary_dark"], width: "49%"}}
										className="flexCenterCenterRow"
										>
										<LanguageIcon sx={{ color: styles["secondary_dark"]}}/>	
										<Typography variant='body1'>
											for Consumer
										</Typography>
									</Link>
									<Divider orientation='vertical'/>
									<Link
										href="https://business.lusid.ai/auth"
										target="_blank" rel="noopener noreferrer"
										sx={{ gap: "10px" , textDecoration: "none", color: styles["secondary_dark"], width: "49%"}}
										className="flexCenterCenterRow"
										>
										<LanguageIcon sx={{ color: styles["secondary_dark"]}}/>
										<Typography variant='body1'>
											for Business 
										</Typography>
									</Link>
								</Box> */}
							</Box>
						</Grid>	
						<Grid item xs={12} sm={6} md lg>
							<Box className="home__products-cards flexCenterSBColumn"
								onClick={() => navigate("/products#mobile-app")}>
								<Box className="flexCenterCenterColumn" sx={{ gap: "40px", height: "calc(100% - 50px)" }}>
									<PhoneIphoneIcon sx={{ color: styles["secondary"], fontSize: "120px" }} />
									<Typography variant='subtitle1'>Lusid Mobile App</Typography>
								</Box>

								{/* <Box 
									sx={{ borderRadius: "8px", gap: "10px", backgroundColor: "white", padding: "10px", width: "100%" }} 
									className="flexCenterSERow">
									<Box className="flexCenter_Row" gap={"10px"}>
										<AppleIcon />
										<Typography variant='body1'>
											App Store
										</Typography>
									</Box>
									<Divider orientation='vertical'/>
									<Box className="flexCenter_Row" gap={"10px"}>
										<AndroidIcon />
										<Typography variant='body1'>
											Play Store
										</Typography>
									</Box>
								</Box> */}
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md lg>
							<Box className="home__products-cards flexCenterSBColumn"
								onClick={() => navigate("/products#extension")}>
								<Box className="flexCenterCenterColumn" sx={{ gap: "40px", height: "calc(100% - 50px)" }}>
									<ExtensionOutlinedIcon sx={{ color: styles["secondary"], fontSize: "120px" }} />
									<Typography variant='subtitle1'>Lusid Extension</Typography>
								</Box>
								{/* <Box 
									sx={{ borderRadius: "8px", gap: "10px", backgroundColor: "white", padding: "10px", width: "100%" }} 
									className="flexCenterCenterRow">
									<DownloadOutlinedIcon />
									<Typography variant='body1'>
										Download
									</Typography>
								</Box> */}
								{/* <Box 
									sx={{ borderRadius: "8px", gap: "10px", backgroundColor: "white", padding: "10px", width: "100%" }} 
									className="flexCenterCenterRow"
									onClick={(e) => { e.stopPropagation() }}>
									<Link
										href="https://business.lusid.ai/auth"
										target="_blank" rel="noopener noreferrer"
										sx={{ gap: "10px" , textDecoration: "none", color: styles["secondary_dark"], width: "49%"}}
										className="flexCenterCenterRow"
										>
										<DownloadOutlinedIcon sx={{ color: styles["secondary_dark"]}}/>
										<Typography variant='body1'>
											for Consumer 
										</Typography>
									</Link>
									<Divider orientation='vertical'/>
									<Link
										href="https://business.lusid.ai/auth"
										target="_blank" rel="noopener noreferrer"
										sx={{ gap: "10px" , textDecoration: "none", color: styles["secondary_dark"], width: "49%"}}
										className="flexCenterCenterRow"
										>
										<DownloadOutlinedIcon sx={{ color: styles["secondary_dark"]}}/>
										<Typography variant='body1'>
											for Business 
										</Typography>
									</Link>
								</Box> */}
							</Box>
						</Grid>
						{/* <Grid item xs={12} sm={6} md lg>
							<Box className="home__products-cards flexFESBRow"	
								onClick={() => navigate("/products#plugin")}>
								<Typography variant='subtitle1'>Lusid Plugin</Typography>
							</Box>
						</Grid> */}
					</Grid>
				</Container>
			</Box>

			<Box>
				<Container
					maxWidth="xl">
					<Box className="flexCenterSBRow">
						<Box 
							className="flexCenterCenterRow" 
							sx={{ width: { xs: "0%", sm: "0%", md: "50%", lg: "50%"},
								backgroundColor: "#f1f1f1", borderRadius: "40px", overflow: "hidden" }}>
							<img
								src={SolutionImg}
								alt="home-icon"
								style={{width: "100%", height: "100%", objectFit: "fill" }}
								/>
						</Box>
						<Box className={"flex_CenterColumn"} 
							sx={{ 
								margin: { xs: "auto", sm: "auto", md: "0px", lg: "0px" },
								height: "80vh", 
								position: "relative"
							}}>
							<Typography 
								sx={{ typography: { xs: "h5", sm: "h5", md: "h4", lg: "h4" }}}>
								Solutions for
								Banks & Card networks.
							</Typography>
							<Typography variant='h6'
								sx={{ color: "grey", marginTop: "20px"}}>
								With our Software-as-a-Service, we enable our
								cilents to deliver  <br/> comprehensive services to their business & individual customers.
							</Typography>
					

						{/* <Box className="flexCenter_Row"
							sx={{ gap: "30px", marginTop: "60px" }}>
							<Button
								variant="contained"
								onClick={() => handleNavigate("/contact-us")}>
								Contact sales
							</Button>
							<Button
								variant="outlined"
								onClick={() => handleNavigate("/products")}>
								Learn more
							</Button>
						</Box> */}

					</Box>

				</Box>
				</Container>
			</Box>

			<Box className="home__solutions">
				<Container
					maxWidth="xl">
					{/* <Typography variant='h4' mb={"20px"} id={"solutions"}>Solutions</Typography> */}

					{/* <Typography variant='body2' sx={{ color: "grey", marginBottom: "20px" }}>
						We are committed to the principle of collaborative synergy, and as such, we have forged partnerships with some of the industry's best and brightest. Our aim is to provide you with use cases and solutions that are not only relevant to the industry but also represent an ideal match for your business needs.
					</Typography> */}

					{/* <Button variant='outlined'
						onClick={() =>  handleNavigate("/solutions")}>Learn more</Button> */}


					{/* <Grid container spacing={2} sx={{ marginTop: "40px"}}>
						<Grid item xs={12} sm={6} md lg>
							<Box className="home__products-solution flexCenterCenterRow"
								sx={{ backgroundColor: "#006039"}}>
								<Box className="flexCenter_Row" sx={{ gap: "10px" }}>
									<Typography variant='h6' sx={{ color: "white" }}>For Consumer</Typography>
								</Box>
							</Box>

						</Grid>
						<Grid item xs={12} sm={6} md lg>
							<Box className="home__products-solution flexCenterCenterRow"
								sx={{ backgroundColor: "#0a66c2"}}>
								<Box className="flexCenter_Row" sx={{ gap: "15px" }}>
									<Typography variant='h6' sx={{ color: "white" }}>For Business</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid> */}

					{/* <Box mt={"100px"} mb={"100px"} className="flexCenterCenterColumn" sx={{ gap: "30px" }}>
						<Typography variant='h5' textAlign={"center"}>
							Comprehensive Solutions for Partner Banks and Card Networks
						</Typography>
						<Typography variant='body1' textAlign={"center"} sx={{ color: "grey" }}>
							Our Software-as-a-Service (SaaS) platform offers a suite of complementary products designed to 
							empower partner Banks and Card Networks. With our cutting-edge technology, 
							we enable our cilents to deliver exceptional services to
							their business and individuals customers.
						</Typography>
					</Box> */}

					<Box mt={"50px"} mb={"00px"} className="flexCenterCenterColumn" sx={{ gap: "50px" }}>
						{/* <Typography variant='h6' textAlign={"center"}>
							Features
						</Typography>
						<Typography variant='h5' textAlign={"center"}>
							Powerful Features for Partner Banks and Card Networks
						</Typography> */}
						<Typography variant='h5' textAlign={"center"}>
							Experience the benefits of our cutting-edge technology
						</Typography>
						
						<Grid container spacing={2} sx={{ marginBottom: "20px"}}>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<MonitorHeartTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Real-time Transaction Monitoring</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Monitor and track transactions in real-time for enhanced security and fraud prevention
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<AssessmentTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Customizable Reporting and Analytics</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Generate detailed reports and gain valuable insights through customizable analytics tools
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<ShieldTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Secure Data Encryption</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Protect sensitive customer information with advanced encryption technology
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Box className="home__products-features flexFSSBRow" gap="15px">
									<CableTwoToneIcon sx={{ color: styles["secondary_light"], fontSize: "50px" }}/>
									<Box className="flex_FSColumn" sx={{ gap: "10px", width: "100%" }} mt={"5px"}>
										<Typography variant='h6'>Comprehensive API Integration</Typography>
										<Typography variant='body1' sx={{ color: "grey" }}>
											Seamlessly integrate our software with partner Banks and Card Networks
										</Typography>
									</Box>
								</Box>
							</Grid>
						</Grid>

						<Button variant='outlined'
							onClick={() => handleNavigate("/products")}>
							Our Products
						</Button>
					</Box>
				</Container>
			</Box>


				<Box className="home__developers" id={"developers"}>
					<Box className="flexCenterCenterColumn" 
						sx={{ gap: "10px", backgroundColor: "#f8f8f8", padding: "60px" }}>
						<Typography variant='h4' className='home__links'>Developers</Typography>
						<Typography variant='h6' sx={{ color: styles["primary_dark"]}} mb={"20px"}>Integrate Lusid APIs in your products</Typography>
						<Link 
							href="https://developer.lusid.ai"
							target="_blank" rel="noopener noreferrer"
							>
							<Button
								variant='outlined'>Developer Portal</Button>
						</Link>
					</Box>	
				</Box>
		</Box>
  )
}

export default HomePage;